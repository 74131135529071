import { useCallback, useState } from "react";

import { useTrackingParams } from "../../../core/application/analytics-use-cases";
import { toSnakeCase } from "../../../core/utils/objects";
import { updateResult } from "../../../organisms/result/repositories/result-repository";
import { useResult, useResultUuid } from "../../../organisms/result/use-cases/result-use-cases";
import { handleUpdateResultErrors } from "../../../utils/error-utils/catch-error-handlers";
import { customCheckoutSession } from "../data/result-repository";

export const useCustomCheckoutSession = (): (() => Promise<unknown>) => {
  const resultUuid = useResultUuid();

  if (!resultUuid) {
    return () => Promise.resolve();
  }

  return async (): Promise<unknown> => {
    return customCheckoutSession(resultUuid);
  };
};

export const useUpdateResultTrackingParams = (): (() => Promise<unknown>) => {
  const result = useResult();
  const trackingParams = useTrackingParams();

  const [isUpdated, setIsUpdated] = useState(false);

  return useCallback(async () => {
    if (!result || !trackingParams || isUpdated) {
      return;
    }

    const trackingParamsSnakeCase = toSnakeCase(trackingParams);
    const needsUpdate = Object.keys(trackingParamsSnakeCase).some(
      (key: string) =>
        trackingParamsSnakeCase[key] !== null &&
        trackingParamsSnakeCase[key] !== result.tracking_params[key]
    );

    if (needsUpdate) {
      setIsUpdated(true);
      try {
        await updateResult(result.uuid, JSON.stringify(trackingParamsSnakeCase));
      } catch (error) {
        handleUpdateResultErrors(error as Error, "tracking-params");
        setIsUpdated(false);
      }
    }
  }, [result, trackingParams, isUpdated]);
};
